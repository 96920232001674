import React from 'react'
import Typography from '@mui/material/Typography';
import './button.css';

const Button = (props) => {
  return (
    <button className="btn-zone" onClick={props.onClick} style={{backgroundColor: props.color}}>
      {props?.children ?
        props.children
        :
        <Typography className="btn-label">
          {props.label}
        </Typography>        
      }

    </button>
  );
}

export default Button;

