import React from 'react';
import Typography from '@mui/material/Typography';
import {device} from "../../utils/deviceType"
import './text.css';

const Text = (props)=> {
  const deviceType = device();
  let size = 30;
  let color = '#f6f6f6';
  let weight = '500';
  let lineHeight;

  if(deviceType == "web" || deviceType == "tablet" ) {
    // Handle size
    switch (props.size) {
      case 'xLarge':
        size = 45;
        lineHeight = 1.2;
        break;
      case 'large':
        size = 30;
        lineHeight = 1.2;
        break;
      case 'normal':
        size = 26;
        lineHeight = 1.2;
        break;
      case 'small':
        size = 20;
        break;
      case 'xSmall':
        size = 18;
        break;
      case 'xxSmall':
        size = 18;
        break;
      default:
        size = 26;
    }
  }

  if(deviceType == "mobile") {
    // Handle size
    switch (props.size) {
      case 'xLarge':
        size = 50;
        lineHeight = 1.2;
        break;
      case 'large':
        size = 30;
        lineHeight = 1.2;
        break;
      case 'normal':
        size = 26;
        lineHeight = 1.2;
        break;
      case 'small':
        size = 20;
        break;
      case 'xSmall':
        size = 18;
        break;
      case 'xxSmall':
        size = 18;
        break;
      default:
        size = 26;
    }
  }

  // Handle color
  switch (props.color) {
    case 'color1':
      color = '#f6f6f6';
      break;
    case 'color2':
      color = '#1a1919';
      break;
    case 'red':
      color = '#ff2d55';
      break;
    default:
      color = '#f6f6f6';
  }

  // Handle weight
  weight = props.weight;

  return (
    <Typography
      className="text-label"
      style={{
        fontSize: size,
        color: color,
        fontWeight: weight,
        lineHeight: lineHeight
     }}
    >
      {props.content}
      {props.children}
    </Typography>
  );
}

export default Text;