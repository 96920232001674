import React from 'react';
import Text from "../components/texts/Text";

const FacialTreatment = () => {
  return (
    <div className="facial-treatment-zone">
      <div className="facial-treatment-titleZone">
        <Text size="large" weight="700" color="color1">
          I trattamenti a misura per te
        </Text>
      </div>
    </div>
  );
}

export default FacialTreatment;