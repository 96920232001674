import React from 'react';
import Grid from '@mui/material/Grid2';
import { motion } from 'framer-motion';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import '../../App.css';
import ElizabethZamoraPhoto from '../../images/team/drssa-elizabeth-zamora.png';
import KathelineOlabarreraPhoto from '../../images/team/drssa-katheline-olabarrera.png';
import Text from '../../components/texts/Text';
import { ReactComponent as Brackets } from "../../icons/dental/brackets.svg";
import { ReactComponent as Implant } from "../../icons/dental/implant.svg";
import { ReactComponent as Lips } from "../../icons/dental/lips.svg";
import { ReactComponent as RootCanal } from "../../icons/dental/root-canal.svg";
import { ReactComponent as Whitening } from "../../icons/dental/whitening.svg";
import { ReactComponent as Crown } from "../../icons/dental/crown.svg";
import './team.css';

const LIGTH_COLOR = '#f6f6f6';

const Team = () => {
  const isMdUp = useMediaQuery('(min-width:900px)');

  const team = [
    {
      position: "Dr.ssa",
      name: "Elizabeth Z.",
      photo: ElizabethZamoraPhoto,
      specialities: [
        {
          label: "Implantologia",
          color: 'rgba(41,38,38,0.97)',
          icon: <Implant className="team-specialityIcon" />
        },
        {
          label: "Protesi", 
          color: 'rgba(41,38,38,0.97)', 
          icon: <Crown className="team-specialityIcon" />
        },
        {
          label: "Endodonzia",
          color: 'rgba(41,38,38,0.97)',
          icon: <RootCanal className="team-specialityIcon" />
        },
        {
          label: "Ortodonzia",
          color: 'rgba(41,38,38,0.97)',
          icon: <Brackets className="team-specialityIcon" />
        }
      ]
    },
    {
      position: "Dr.ssa",
      name: "Katheline O.",
      photo: KathelineOlabarreraPhoto,
      specialities: [
        {
          label: "Estetica Dentale",
          color: 'rgba(41,38,38,0.97)',
          icon: <Whitening className="team-specialityIcon" />
        },
        {
          label: "Implantologia",
          color: 'rgba(41,38,38,0.97)',
          icon: <Implant className="team-specialityIcon" />
        },
        {
          label: "Acido Ialuronico",
          color: 'rgba(41,38,38,0.97)',
          icon: <Lips className="team-specialityIcon" />
        },
        {
          label: "Ortodonzia",
          color: 'rgba(41,38,38,0.97)',
          icon: <Brackets className="team-specialityIcon" />
        }
      ]
    }
  ];

  return (
    <div className="team-zone">
      <div className="team-titleZone">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <Text size="large" weight="700" color="color2">
            La nostra equipe
          </Text>
        </motion.div>
      </div>

      <Grid container spacing={2} className="team-contentZone" justifyContent="center">
  {team.map((member, index) => (
    <Grid 
      size={{ xs: 12, sm: 12, md: 6, lg: 6 }} 
      className="team-card" 
      key={index} 
      style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
    >
      <motion.div
        initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true, amount: 0.2 }}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: isMdUp
            ? index % 2 === 0
              ? 'flex-end'
              : 'flex-start'
            : 'center',
          alignItems: 'center'
        }}
      >
        <div className="team-cardWrapper">
          <div className="team-backCard" />
          <div className="team-frontCard">
            <div className="team-cardHeader">
              <div className="team-photoZone">
                <img src={member.photo} className="team-photo" alt={member.name} />
              </div>
            </div>
            <Text size="small" weight="700" color="color1" content={`${member.position} ${member.name}`} />
            <div className="team-specialities">
              {member.specialities.map((speciality, specialityIndex) => (
                <Tooltip title={speciality.label} key={specialityIndex}>
                  <div className="team-specialityIconZone" style={{ backgroundColor: speciality.color }}>
                    {speciality.icon}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </Grid>
  ))}
</Grid>

    </div>
  );
}

export default Team;