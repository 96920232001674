import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import ReactCardFlip from 'react-card-flip'
import Button from '../button'

import brackets from "../../icons/dental/brackets.svg"
import dentalCare from "../../icons/dental/dental-care.svg"
import facialTreatment from "../../icons/dental/facial-treatment.svg"
import implant from "../../icons/dental/implant.svg"
import lips from "../../icons/dental/lips.svg"
import oralSurgery from "../../icons/dental/oral-surgery.svg"
import rootCanal from "../../icons/dental/root-canal.svg"
import whitening from "../../icons/dental/whitening.svg"
import toothFairy from "../../icons/dental/tooth-fairy.svg"
import crown from "../../icons/dental/crown.svg"

import lipsImage from "../../images/treatments/lips.png"
import oralSurgeryImage from "../../images/treatments/oral-surgery.png"
import orthodonticImage from "../../images/treatments/orthodontic.png"
import prosthesisImage from "../../images/treatments/prosthesis.png"
import childTreatmentImage from "../../images/treatments/child-treatment.png"
import implantologyImage from "../../images/treatments/implantology.png"
import dentalCareImage from "../../images/treatments/dentalCare.png"
import facialTreatmentImage from "../../images/treatments/facialTreatment.png"
import endodonthicImage from "../../images/treatments/endodonthic.png"
import whiteningImage from "../../images/treatments/whitening.png"
import {device} from "../../utils/deviceType";
import './flip-card.css';

const HEADER_HEIGHT = 70;
const CARD_HEIGHT = 420;
const LEFT_LINE = 5;
const PADDING_TEXT_ZONE = 15;
const PADDING_LEFT_TEXT_ZONE = LEFT_LINE + PADDING_TEXT_ZONE;

const deviceType = device();

export const FlipCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  /* Loading the good icon */
  let image;
  switch (props.type) {
    case 'orthodontic':
      image = orthodonticImage;
      break;
    case 'childTreatment':
      image = childTreatmentImage;
      break;
    case 'prosthesis':
      image = prosthesisImage;
      break;
    case 'dentalCare':
      image = dentalCareImage;
      break;
    case 'facialTreatment':
      image = facialTreatmentImage;
      break;
    case 'implantology':
      image = implantologyImage;
      break;
    case 'lips':
      image = lipsImage;
      break;
    case 'oralSurgery':
      image = oralSurgeryImage;
      break;
    case 'endodonthic':
      image = endodonthicImage;
      break;
    case 'whitening':
      image = whiteningImage;
      break;
  }

  /* Loading the good icon */
  let Icon;
  switch (props.type) {
    case 'childTreatment':
      Icon = <embed src={toothFairy} className="flip-card-icon" />;
      break;
    case 'dentalCare':
      Icon = <embed src={dentalCare} className="flip-card-icon" />;
      break;
    case 'endodonthic':
      Icon = <embed src={rootCanal} className="flip-card-icon" />;
      break;
    case 'facialTreatment':
      Icon = <embed src={facialTreatment} className="flip-card-icon" />;
      break;
    case 'implantology':
      Icon = <embed src={implant} className="flip-card-icon" />;
      break;
    case 'lips':
      Icon = <embed src={lips} className="flip-card-icon" />;
      image = lipsImage;
      break;
    case 'oralSurgery':
      Icon = <embed src={oralSurgery} className="flip-card-icon" />;
      break;
    case 'orthodontic':
      Icon = <embed src={brackets} className="flip-card-icon" />;
      break;
    case 'prosthesis':
      Icon = <embed src={crown} className="flip-card-icon" />;
      break;
    case 'whitening':
      Icon = <embed src={whitening} className="flip-card-icon" />;
      break;
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  }

  const handleFlipOnClick = () => {
    setIsFlipped(!isFlipped);
  }

  return (
    <div>
    <div
      onMouseEnter={ () => handleFlipOnClick() }
      onMouseLeave={ () => handleFlipOnClick() }
    >
      <ReactCardFlip
        isFlipped={isFlipped}
        flipSpeedBackToFront={2}
        flipSpeedFrontToBack={2}
      >
        <div className="flip-card-frontalZoneWrapper">
          <div
            className="flip-card-frontalZone"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
          <div className="flip-card-filter" />
          <div style={{ maxWidth: '90%'}}>
            <Typography className="flip-card-title">{props.title}</Typography>
          </div>
        </div>

        <div className="flip-card-rearZone">
          <div className="flip-card-rearTextZone">
            <div className="flip-card-header">
              {Icon}
            </div>
            <Typography className="flip-card-text">{props.text}</Typography>
            <div className="flip-card-button">
              <Button label='Scopri di più' color={props.color} />
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </div>

    </div>
  )
}
