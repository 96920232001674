import React from 'react'
import Text from "../../components/texts/Text"
import notFound from "../../images/errors/not-found.svg";
import './not-found.css';

const NotFound = () => {
  return (
    <div className="not-found-zone">
      <div className="not-found-contentZone">
        <embed src={notFound} className="not-found-image" />
        <div className="not-found-contentTextZone">
          <Text size="small" weight="700" color="color1">
            Pagina Non Trovata
          </Text>
          <Text size="xxSmall" color="color1">
            Oops ! La pagina che cerchi non è stata trovata.
          </Text>
        </div>
      </div>
    </div>
  );
}

export default NotFound;