import React from 'react'
import Text from "../components/texts/Text"

const OralSurgery = () => {

  return (
    <div>
      <div >
        <Text size="large" weight="700" color="color1" content="I trattamenti a misura per te" />
      </div>
    </div>
  );
}

export default OralSurgery;