import React, { useEffect, useState } from 'react'
import { AppBar, Fade, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '../../components/button'
import logo from '../../images/logo.svg'
import Text from '../../components/texts/Text'
import Team from './Team'
import Quotes from './Quotes'
import Treatments from './Treatments'
import Info from './Info'
import Services from './Services'
import { device } from "../../utils/deviceType"
import { Helmet } from "react-helmet"
import { ReactComponent as CalendarIcon } from "../../icons/dental/calendar.svg"
import './home.css';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const deviceType = device();


/*
let background;
if(deviceType == "mobile") {
  background = require('../../images/background.png');
}
else {
  background = require('../../images/background.png');
}*/

const Home = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)


    if(deviceType == "web") {
      window.addEventListener('resize', debouncedHandleResize);
    }

    setTimeout(() => {
      setChecked(true); // count is 0 here
    }, 700);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    window.open('https://www.miodottore.it/strutture/studio-dentistico-zamora?saasonly=true&utm_source=google&utm_medium=gmb&utm_campaign=50066');
  };

  return (
    <div className="home-app">
      <AppBar className="home-topBar">
        <Toolbar className="home-toolBar">
          <embed src={logo} className="home-topBarLogo"/>
        </Toolbar>
      </AppBar>
        <Grid container spacing={0}>
          <Helmet>
            <title>Studio Dentistico Zamora | Home</title>
            <meta name="description" content="Da molti anni nello Studio Dentistico Zamora ci occupiamo di voi e del vostro sorriso. Da noi potete realizzare ogni tipo di trattamento dentale di alta qualità..." />
            <link rel="canonical" href="https://studiodentisticozamora.com" />
          </Helmet>

          <Grid item xs className="home-unitWrapper">
            <div className="home-welcomeZone">
              <div className="home-welcomeZoneFilter">
                <div className="home-welcome-content">
                  <Fade timeout={2500} in={true}>
                    <div className="home-welcomeTitleZone">
                      <Text size="xLarge">
                        Studio Dentistico Zamora
                      </Text>
                    </div>

                  </Fade>
                  <Fade timeout={2500} in={checked} >
                    <div className="home-welcomeSubTitleZone">
                      <Text size="normal">
                        Enjoy your smile
                      </Text>
                    </div>
                  </Fade>

                  {(deviceType == "web" && dimensions.width >= 600 ?
                    <Grid container spacing={3}>
                      <Grid item xs={9} style={{textAlign: "left"}}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <div className="home-appContentTextZone">
                              <Text size="small">
                                Il sorriso è un diritto di tutti e vedervi felici è la nostra priorità. Ormai sono molti anni che ci occupiamo di voi e della vostra bocca. La nostra passione è il nostro lavoro.
                              </Text>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    : null
                  )}
                  <div
                    className="home-calendarBtnWrapper"
                  >
                    <Button color={"#004D80"} onClick={() => handleClick()}>
                      <CalendarIcon className="home-calendarBtnIcon" />
                      <Text size="small" weight="500">
                        Prenota online
                      </Text>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

      <Team />
      <Treatments />
      <Services dimensions={dimensions}/>
      <Quotes />
      <Info dimensions={dimensions}/>
    </div>
  );
}

export default Home;