export const device = () => {
  const maxTouchPoints = navigator.maxTouchPoints;
  const resolution = window.innerWidth;

  if(maxTouchPoints == 0) {
    return "web";
  }
  else {
    if(resolution <= 480) {
      return "mobile";
    }
    else {
      return "tablet";
    }
  }
}