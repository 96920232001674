import React, { useEffect, useState } from 'react'
import Home from './screens/home/Home'
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom"
import NotFound from "./screens/errors/NotFound"
import OralSurgery from "./screens/OralSurgery"
import FacialTreatment from "./screens/FacialTreatment"

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route path="/treatments/oral-surgery">
          <OralSurgery />
        </Route>
        <Route path="/treatments/facial-treatment">
          <FacialTreatment />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;