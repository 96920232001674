import React from 'react'
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';
import partnershipsImage from '../../images/partnerships.png';
import dentistEquipmentsImage from '../../images/dentist-equipments.png';
import Text from "../../components/texts/Text";
import './services.css';

const MD_MIN_WIDTH = 960;

const Services = (props) => {
  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 }
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <div className="services-zoneWrapper">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInLeft}
        transition={{ duration: 2 }}
      >
        <div className="services-titleZone">
          <Text size="large" weight="700" color="color2" content="Servizi di alta qualità" />
        </div>
      </motion.div>

      <div className="services-zone">
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid container justify="center" alignItems="center" spacing={10}>
            <Grid container justify="center" alignItems="center" spacing={10}>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5} style={{ order: props.dimensions.width < MD_MIN_WIDTH ? 2 : 1 }}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={fadeIn}
                  transition={{ duration: 3.5 }}
                >
                  <div className="services-bottomText">
                    <Text size="xxSmall" color="color2" content="Collaboriamo con un laboratorio 100% italiano che ci permette di portare a termine lavori di vera eccellenza. Abbiamo la possibilità di lavorare fianco a fianco con il tecnico protesico che grazie al suo team riesce a realizzare lavori di vero prestigio." />
                  </div>
                  <Text size="xxSmall" color="color2" content="Nella clinica Zamora cerchiamo sempre di puntare sulla qualità dei nostri macchinari, offriamo modelli di ultima generazione che ci permettono di dare una dose minima nell’esposizione di radiazione." />
                </motion.div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7} xl={7} style={{ paddingBottom: 0, order: props.dimensions.width < MD_MIN_WIDTH ? 1 : 2 }}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={fadeIn}
                  transition={{ duration: 3.5 }}
                >
                  <img src={dentistEquipmentsImage} alt="Logo" className="services-partnershipImage" />
                </motion.div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={fadeIn}
                transition={{ duration: 3.5 }}
              >
                <img src={partnershipsImage} alt="Logo" className="services-partnershipImage" />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={fadeIn}
                transition={{ duration: 3.5 }}
              >
                <div className="services-bottomText">
                  <Text size="xxSmall" color="color2" content="Pensiamo che alla base del successo di un trattamento ci sia la qualità del prodotto e per questo motivo vi mostriamo alcune delle imprese con cui lavoriamo." />
                </div>
                <Text size="xxSmall" color="color2" content="I risultati si vedono nelle bocche dei nostri pazienti." />
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Services;