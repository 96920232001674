import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import email from "../../icons/email.svg"
import smartphone from "../../icons/smartphone.svg"
import homePhone from "../../icons/home-phone.svg"
import Text from "../../components/texts/Text"
import './info.css';

const GRID_SPACING = 2;

let weekDay = new Date().getDay();

const timetable = [
  {
    day: { number: 1, letter: 'Lunedì' },
    schedule: { start: '08.00', end: '20.00' },
    open: true
  },
  {
    day: { number: 2, letter: 'Martedì' },
    schedule: { start: '08.00', end: '20.00' },
    open: true
  },
  {
    day: { number: 3, letter: 'Mercoledì' },
    schedule: { start: '08.00', end: '20.00' },
    open: true
  },
  {
    day: { number: 4, letter: 'Giovedì' },
    schedule: { start: '08.00', end: '20.00' },
    open: true
  },
  {
    day: { number: 5, letter: 'Venerdì' },
    schedule: { start: '08.00', end: '20.00' },
    open: true
  },
  {
    day: { number: 6, letter: 'Sabato' },
    schedule: { start: '08.00', end: '12.00' },
    open: true
  },
  {
    day: { number: 0, letter: 'Domenica' },
    schedule: { start: '', end: '' },
    open: false
  }
];

const contactInfo = [
  {
    name: 'Email',
    data: 'studiodentistico.zamora@gmail.com',
    icon: email
  },
  {
    name: 'Fisso',
    data: '(+39) 320 3608 765',
    icon: smartphone
  },
  {
    name: 'Cellulare',
    data: '(+39) 02 3673 6916',
    icon: homePhone
  },
];

const Info = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  return (
    <div style={{ flex: 1 }}>
      <div className="info-titleZone">
        <Text size="large" weight="700" color="color2">
          Informazioni
        </Text>
      </div>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12} sm={12} md={6} lg={5} spacing={10}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <div className="info-zone">
                <div className="info-subTitleZone">
                  <Text size="xSmall" weight="700" color="color2">
                    Orario
                  </Text>
                </div>
                <hr className="info-separator" />
                {timetable.map((timetable,index) => (
                  <div className="info-dayScheduleZone">
                    <div className="info-dayZone">
                      {timetable.day.number != weekDay ?
                        <Text size="xxSmall" color="color2">{timetable.day.letter}</Text>
                        :
                        <Text size="xxSmall" weight="700" color="red">{timetable.day.letter}</Text>
                      }
                    </div>
                    {(timetable.open == true ?
                      (timetable.day.number != weekDay ?
                        <Text size="xxSmall" color="color2" content={timetable.schedule.start + " - " + timetable.schedule.end} />
                        :
                        <Text size="xxSmall" weight="700" color="red" content={timetable.schedule.start + " - " + timetable.schedule.end} />
                      )
                      :
                      (timetable.day.number != weekDay ?
                        <Text size="xxSmall" color="color2">Chiuso</Text>
                        :
                        <Text size="xxSmall" weight="700" color="red">Chiuso</Text>
                      )
                    )}
                  </div>
                ))}
                <hr className="info-separator" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="info-zone">
                <div className="info-subTitleZone">
                  <Text size="xSmall" weight="700" color="color2">Contatto</Text>
                </div>
                {contactInfo.map((info, index) => (
                  <div>
                    <Box display="flex" justifyContent="center">
                      <Box p={1} className="info-iconZone">
                        <embed src={info.icon} className="info-icon" />
                      </Box>
                    </Box>

                    <div className="info-infoZone">
                      <Text size="xxSmall" color="color2">{info.data}</Text>
                      {(index != contactInfo.length - 1 ?
                        (<hr className="info-infoSeparator"/>) : null
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={7}>
          <div style={{ height: '100%', minHeight: 450}}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.214030838128!2d9.232281550475001!3d45.50576993858822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c70e65dd69ff%3A0x60f9a7301542f0bd!2sVia%20Privata%20Paolo%20Gerolamo%20Biumi%2C%203%2C%2020128%20Milano%20MI%2C%20Italie!5e0!3m2!1sfr!2sfr!4v1610582944587!5m2!1sfr!2sfr"
              title='Google Maps - Studio dentistico Zamora'
              width='100%'
              height='100%'
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0">
            </iframe>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Info;