import React, {useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import men1 from '../../icons/people/man-1.svg'
import woman1 from '../../icons/people/woman-1.svg'
import woman2 from '../../icons/people/woman-2.svg'
import Text from "../../components/texts/Text";
import {device} from "../../utils/deviceType";
import './quotes.css';

const deviceType = device();

const Quotes = (props) => {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div className="quotes-Zone">
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}>
        <Grid container className={deviceType != "mobile" ? "quotes-Wrapper" : "quotes-WrapperMobile"}>
          <Grid item xs={0}>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={deviceType != "mobile" ? "quotes-LeftZone" : "quotes-ZoneMobile"}>
                <Typography className={deviceType != "mobile" ? "quotes-Mark" : "quotes-MarkMobile"}>
                  “
                </Typography>
                <Text size="small" weight="700" color="color1">
                  Ho sempre avuto paura di andare dal dentista, ma da loro è diverso, mi mettono a mio agio e sono sempre cordiali con me. Piano piano sto sconfiggendo questo mio timore.
                </Text>
                <div className="quotes-AuthorZone">
                  <embed src={woman1} className="quotes-AuthorImage"/>
                  <div className="quotes-AuthorNameZone">
                    <Text size="xSmall" color="color1">
                      Carlotta F. , Paziente, Milano
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-end" className={deviceType != "mobile" ? "quotes-Wrapper" : "quotes-WrapperMobile"}>
          <Grid item xs={12}>
            <div className={deviceType != "mobile" ? "quotes-RightZone" : "quotes-ZoneMobile"}>
              <Typography className={deviceType != "mobile" ? "quotes-Mark" : "quotes-MarkMobile"}>
                “
              </Typography>
              <Text size="small" weight="700" color="color1">
                Non mi sono mai curato dei miei denti e sapevo che avrei dovuto prima o poi affrontare questo problema.
              </Text>
              <Text size="small" weight="700" color="color1"
                    content="Dopo il colloquio con la Dott.ssa Zamora ho capito che dovevo iniziare questo percorso, adesso sono veramente felice."/>
              <div className="quotes-AuthorZone">
                <embed src={men1} className="quotes-AuthorImage" />
                <div className="quotes-AuthorNameZone">
                  <Text size="xSmall" color="color1">
                    Federico M. , Paziente, Bergamo
                  </Text>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container className={deviceType != "mobile" ? "quotes-Wrapper" : "quotes-WrapperMobile"} >
          <Grid item xs={0}>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={deviceType != "mobile" ? "quotes-LeftZone" : "quotes-ZoneMobile"}>
                <Typography className={deviceType != "mobile" ? "quotes-Mark" : "quotes-MarkMobile"}>
                  “
                </Typography>
                <Text size="small" weight="700" color="color1">
                  Quando ridevo mettevo sempre la mano davanti al volto per la vergogna dei miei denti. Parlare con le Dott.sse mi ha fatto capire che potevo trovare una soluzione al mio problema.
                </Text>
                <div className="quotes-AuthorZone">
                  <embed src={woman2} className="quotes-AuthorImage"/>
                  <div className="quotes-AuthorNameZone">
                    <Text size="xSmall" color="color1">
                      Valentina R. , Paziente, Svizzera
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Quotes;

